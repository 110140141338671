import React from "react"
import { graphql } from "gatsby"
import { slugify } from "../utils/transcriptSlugOld"
import Layout from "../components/layout"
import Seo from "../components/global/seo"
import BreadCrumbs from "../components/global/BreadCrumbs"
import CompaniesList from "../components/catalog/catalogList"

const City = ({ data, pageContext }) => {
  // const cities = data.allMapCityCsv.nodes
  const companies = data.allTahoservCsv.nodes
  const {
    cityName,
    cityNameChanged,
    oblName,
    oblNameRodovyi,
    cityTypeChanged2,
    slug,
  } = pageContext
  //
  const fullCityObl =
    cityName === "Київ"
      ? `в ${cityNameChanged}`
      : `в ${cityNameChanged}, ${oblNameRodovyi} області`

  const companyBreadCrumbs = [
    { position: 1, name: "Тахографи", url: "/", isLast: false },
    { position: 2, name: "Компанії", url: "/kompaniyi/", isLast: false },
    {
      position: 3,
      name: `${oblName} область`,
      url: `/kompaniyi/${slugify(oblName)}/`,
      isLast: false,
    },
    { position: 4, name: cityName, url: "", isLast: true },
  ]

  return (
    <Layout>
      <Seo
        title={`Обслуговання тахографів ${fullCityObl}`}
        description={`Знайти в компаніях міста ${cityName}, ${oblNameRodovyi} області з продажу, обслуговання і ремонту тахографів на порталі Taho.org.ua.`}
        noindex={companies.length > 1 ? false : true}
        canonical={
          companies.length < 2
            ? `/kompaniyi/${slugify(oblName)}/`
            : `/kompaniyi${slug}`
        }
      />
      <main className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* BreadCrumbs */}
        <BreadCrumbs breadcrumbs={companyBreadCrumbs} />

        <h1 className="text-4xl font-bold mt-6 mb-4">
          Тахографи {fullCityObl}
        </h1>
        <div className="py-4">
          Компанії {cityTypeChanged2} {cityName}, {oblNameRodovyi} сертифіковані
          Укртрансбезпекою для роботи з Тахографами.
        </div>
        <CompaniesList companies={companies} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($oblName: String!, $uniqueName: String!) {
    allMapCityCsv(filter: { cityObl: { eq: $oblName } }) {
      nodes {
        id
        cityName
        cityNameChanged
        cityObl
        cityOblChanged
        cityType
        cityTypeChanged
        cityTypeShort
        uniqueName
        companiesCount
        slug
      }
    }
    allTahoservCsv(
      filter: { mapCityObl: { eq: $uniqueName } }
      sort: { fields: sortID, order: DESC }
    ) {
      nodes {
        id
        slug
        idNumber
        title
        shortname
        companyType
        address
        mapCity
        mapObl
        verifServ
        digitServ
        analogServ
        mapCityObl
      }
    }
  }
`

export default City
